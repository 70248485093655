import ArticleIndexPage, {
  ArticleIndexPageProps,
} from '../../../components/ArticleIndexPage/ArticleIndexPage';
import React from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import configureSentryScope from '../../../helpers/configureSentryScope';
import getArticleIndexPageViewModels from '../../../helpers/getArticleIndexPageViewModels';
import { getPlaiceholder } from 'plaiceholder';
import convertImageUrlToS3ImageUrl from '../../../helpers/convertImageUrlToS3ImageUrl';
import buildComponentViewModelsFromEnhancedMarkdowns from '../../../helpers/buildComponentViewModelsFromEnhancedMarkdowns';
import getGameSlugs from '../../../helpers/getGameSlugs';
import { initRequest } from '../../../helpers/rpgLogsApiRequestMetadata';

export const ArticleIndexPagePath = '/[gameSlug]/articles';

export default function IndexPage(props: ArticleIndexPageProps): JSX.Element {
  return <ArticleIndexPage {...props} />;
}

export const getStaticPaths: GetStaticPaths = async () => {
  const gameSlugs = await getGameSlugs();

  return {
    paths: gameSlugs.map((gameSlug) => ({
      params: {
        gameSlug,
      },
    })),
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  configureSentryScope(params);

  if (!params?.gameSlug || typeof params.gameSlug !== 'string') {
    return {
      notFound: true,
    };
  }

  if (params.gameSlug === 'classic-wrath') {
    return {
      redirect: {
        statusCode: 307,
        destination: '/classic-cata/articles',
      },
    };
  }

  initRequest({
    route: '/[gameSlug]/articles',
    path: `/${params.gameSlug}/articles`,
  });

  const pageViewModels = await getArticleIndexPageViewModels(params.gameSlug);

  const context = {
    pageViewModels,
    // Needs to be passed as argument instead of import to prevent node libraries attempting to be imported on client side
    getPlaiceholder: async (src: string) =>
      await getPlaiceholder(convertImageUrlToS3ImageUrl(src)),
  };

  const viewModels = await buildComponentViewModelsFromEnhancedMarkdowns(
    [
      pageViewModels.articleIndexPage.prominentArticle,
      ...pageViewModels.articleIndexPage.featuredArticles,
      ...pageViewModels.articleIndexPage.recentArticles,
    ].map((articlePreview) => `<Image src="${articlePreview.imageUrl}">`),
    context
  );

  return {
    props: {
      googleAnalytics: pageViewModels.googleAnalytics,
      game: pageViewModels.game,
      header: pageViewModels.header,
      headerTitle: pageViewModels.headerTitle,
      footer: pageViewModels.footer,
      cmsNavigation: pageViewModels.cmsNavigation,
      articleIndexPage: pageViewModels.articleIndexPage,
      viewModels,
    },
    revalidate: 600,
  };
};
